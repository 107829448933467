import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      name: "Korrie O'Crevy",
      description: 'kocrevy0@thetimes.co.uk',
      status: 'Professional',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      name: 'Merline Penhalewick',
      description: 'mpenhalewick9@php.net',
      status: 'Professional',
    },
  ],

  /* eslint-disable global-require */
}

mock.onGet('/good-table/basic').reply(() => [200, data.tableBasic])
mock.onPost('/good-table/add').reply(config => {
  console.log(config.data)

  const doc = JSON.parse(config.data);

  console.log("le doc",doc)
  data.tableBasic.push(doc)
  console.log("la liste",data.tableBasic)

  return [201, { doc }]

})
