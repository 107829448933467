import mock from './mock'

/* eslint-disable import/extensions */


// Table
import './data/extensions/good-table'



/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
