<template>
    <b-button class="button"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      size="large"
      :name="name"
      
    >
    <feather-icon
      :icon="icon"
      class="icon"

    />
      Ajouter
    </b-button>
</template>

<script >
import {
  BButton
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'


export default ({
    components : {
        BButton,
    },

    directives: {
        Ripple,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: false,
      }
    },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

    .button{
      margin-bottom: 28px;
      height: 45px;
      width: 150px;
      font-size: 1.1rem;

      .icon{
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }

</style>
