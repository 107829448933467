<template>
  <b-card-code title="">

    <b-form-group class="select"
          label="Catégorie de documents"
          label-for="bg-variant"
          label-cols-sm="4"
          label-cols-lg="12"
          centered
        >
          <b-form-select
            defaultValue="0"
            @change="selectDoc(document)"
            id="bg-variant"
            v-model="document"
            :options="documents"
          />

    </b-form-group>
    <div class="header-elements-sub-doc">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- Add input -->
    <b-button class="button-add"
      id="toggle-btn-add"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      v-b-modal.modal-sub-doc-add
      :disabled = disabled
    >
    <feather-icon
      icon="FolderPlusIcon"
      class="icon-add"
    />
      Ajouter
    </b-button>

    </div>

    <!-- modal -->
    <b-modal
    id="modal-sub-doc-add"
    ref="sub-doc-modal"
    title="Ajouter une sous-catégorie de document"
    ok-title="Confirmer"
    cancel-variant="outline-secondary"
    @show="resetModalAdd"
    @hidden="resetModalAdd"
    @ok="handleOkAdd"
    centered
    v-model="addOpen"
    >

    <form
        ref="form"
        @submit.stop.prevent="handleSubmit()"
    >
        <b-form-group
        :state="nameState"
        label="Nom"
        label-for="name-input"
        invalid-feedback="Ce champ est requis"
        >
        <b-form-input
            required
            id="name-input"
            v-model="name"
            :state="nameState"
        />

        </b-form-group>

        <b-form-group
        :state="descriptionState"
        label="Description"
        label-for="description-input"
        >
        <b-form-input
            id="description-input"
            v-model="description"
            :state="descriptionState"
        />
        </b-form-group>
        <b-form-group class="select"
          label="Tag"
          label-for="bg-variant"
          label-cols-sm="4"
          label-cols-lg="12"
          centered
          :state="markAsState"
        >
          <b-form-select
            defaultValue="0"
            id="bg-variant"
            v-model="markAs"
            :options="tags"
            :state="markAsState"
          />
        </b-form-group>
    </form>
    </b-modal>

    <!-- table -->
    <vue-good-table class="tablecus"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'markAs'">
          <span v-if="props.row.markAs">
            <b-badge :variant="statusVariant(props.row.markAs)">
              {{ props.row.markAs }}
            </b-badge>
          </span>
          <span v-else>
            {{ props.row.markAs }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
              v-b-modal.modal-prevent-closing-update
              @click="update(props.row)"
              
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>
                  Modifier
                </span>
              </b-dropdown-item>

              <b-modal
                id="modal-prevent-closing-update"
                ref="my-modal-update"
                title="Modifier la sous-catégorie de document"
                ok-title="Submit"
                cancel-variant="outline-secondary"
                @show="ModalUpdate(props)"
                @hidden="resetModalUpdate"
                @ok="handleOkUpdate"
                v-model="updateOpen"
                centered
                :name="name"
              >
                <form
                  ref="formUpdate"
                  @submit.stop.prevent="handleUpdate()"
                >
                  <b-form-group
                    :state="nameStateUpdate"
                    label="Nom"
                    label-for="name-input"
                    invalid-feedback="Ce champ est requis"
                  >
                    <b-form-input
                      id="name-input"
                      :value="props.row.name"
                      v-model="name"
                      :state="nameStateUpdate"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    :state="descriptionState"
                    label="Description"
                    label-for="description-input"
                    
                  >
                    <b-form-input
                      id="description-input"
                      v-model="description"
                      :state="descriptionState"
                      
                    />
                  </b-form-group>
                  <b-form-group class="select"
                    label="Tag"
                    label-for="bg-variant"
                    label-cols-sm="4"
                    label-cols-lg="12"
                    centered
                    :state="markAsState"
                  >
                    <b-form-select
                      defaultValue="0"
                      id="bg-variant"
                      v-model="markAs"
                      :options="tags"
                      :state="markAsState"
                    />

                  </b-form-group>
                </form>
              </b-modal>

              <b-dropdown-item
              @click="showMsgBoxTwo(props.row)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>
                  Supprimer
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
      <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import axios from 'axios';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,VBModal,BModal,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from '../code'
import { props } from 'vue-prism-component'
import { getInstance } from '../../auth';

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      
      pageLength: 10,
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'Sous-catégorie',
          field: 'name',
        },
        {
          label: 'Description',
          field: 'description',
        },

        {
          label: 'Tag',
          field: 'markAs',
        },

        {
          label: 'Action',
          field: 'action',
          custom: true,
        },
      ],
      rows: [],
      searchTerm: '',

      name: '',
      nameState: null,
      nameStateUpdate:null,
      description: '',
      descriptionState: null,
      markAs: '',
      markAsState: null,
      addOpen: false,
      updateOpen:false,
      id: '',
      currentName:'',
      currentDescription:'',
      currentMarkAs:'',
      documents:[],
      documentsMapped:new Map(),
      document:'',
      disabled: true,
      tags: [
        'ASSEMBLIES',
        'PRESIDENT_DECISIONS', 
        'COMMITTEES_MINUTES'
      ]
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        ASSEMBLIES : 'light-success',
        PRESIDENT_DECISIONS : 'light-info',
        COMMITTEES_MINUTES : 'light-secondary'
        /* eslint-enable key-spacing */
      }

      return tag => statusColor[tag]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {

    axios
      .get('https://uplaw-api.com/doc-categories')
      .then(res => {
        res.data.map(element =>{
            this.documents.push(element.name)
            this.documentsMapped.set(element.id,element.name)
        })
      })
  },
  methods: {
    getKey(value) {
        return [...this.documentsMapped].find(([key, val]) => val == value)[0]
    },
    selectDoc(doc){
        let docId = this.getKey(doc)
        axios
      .get(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category?offset=0&limit=30`)
      .then(res => {
        this.rows= res.data
      })
      this.disabled=false
    },

    add(){
      this.addOpen= true;
    },
    update(props){
      this.id=props.id;
      this.updateOpen= true;
      this.currentName=props.name;
      this.currentDescription=props.description;
      this.currentMarkAs=props.markAs;
    },

    showModalAdd(){
      this.name = '';
      this.nameState = null;
      this.description = '';
      this.descriptionState = null;
      this.markAs = '';
      this.markAsState = null;
    },

    resetModalAdd() {
      this.name = '';
      this.nameState = null;
      this.description = '';
      this.descriptionState = null;
      this.markAs = '';
      this.markAsState = null;
      this.addOpen = false;

    },
    ModalUpdate(props){
      this.name=this.currentName;
      this.description=this.currentDescription;
      this.markAs=this.currentMarkAs;
    },

    resetModalUpdate() {
      this.name = '';
      this.nameStateUpdate = null;
      this.description = '';
      this.descriptionState = null;
      this.updateOpen = false;
    },
    
    handleOkAdd(bvModalEvtAdd) {
      // Prevent modal from closing
      bvModalEvtAdd.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleOkUpdate(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleUpdate()
    },
    async checkFormValidity() {
      const valid = await this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },

    async checkUpdateValidity(){
      const valid = await this.$refs.formUpdate.checkValidity()
      this.nameStateUpdate = valid
      return valid
    },

    async handleSubmit() {

      const token = await getInstance().getTokenSilently()
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }

      if (await this.checkFormValidity()==false) {
        return
      }
      else {
        let docId = this.getKey(this.document)
        await  axios
      .post(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category`,{
        name:this.name,
        description:this.description,
        markAs:this.markAs,
      },
      config
      )
      .then(res => {
        axios
      .get(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category?offset=0&limit=30`)
      .then(res => {
        this.rows = res.data
      })
      })
      }

      this.$nextTick(() => {
        this.$refs['sub-doc-modal'].toggle('#toggle-btn')
      })
        
    },

    async handleUpdate() {

      const token = await getInstance().getTokenSilently()
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }

      if (await this.checkUpdateValidity()==false) {
        return
      }
      else{
        let docId = this.getKey(this.document)
        await axios
      .put(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category/${this.id}`,{
        name:this.name,
        description:this.description,
        markAs: this.markAs
      },
      config
      )
      .then(res => {
        this.name='',
        this.description='',
        this.markAs= '',
        this.id = '';
        axios
      .get(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category?offset=0&limit=30`)
      .then(res => {
        this.rows = res.data
      })
      })
      }
      

      this.$nextTick(() => {
        this.$refs['my-modal-update'].toggle('#toggle-btn')
      })
    },

    async showMsgBoxTwo(props) {

      const token = await getInstance().getTokenSilently()
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }

      let docId = this.getKey(this.document)
      this.id=props.id

      this.$bvModal
        .msgBoxConfirm('Êtes-vous sûr(e) de vouloir supprimer cette catégorie ?', {
          modalClass:"modal-danger",
          title: 'Supprimer',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value ==true) {
            axios
            .delete(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category/${this.id}`, config)
            .then(res => {
            this.id = '';
            axios
          .get(`https://uplaw-api.com/doc-category/${docId}/doc-sub-category?offset=0&limit=30`)
          .then(res => {
            this.rows = res.data
          })
          })

          }
        })
    },

  }

}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
  .select{
    margin-top :-20px;
    width : 50%;
  }
  .header-elements-sub-doc{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 20px;

    .button-add{
      margin-bottom: 28px;
      height: 45px;
      width: 150px;
      font-size: 1.1rem;
      .icon-add{
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .tablecus{
    margin-top: 20px;
  }
</style>