import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

import { Auth0Plugin } from './auth';


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
import '@/@fake-db/db'

// import Zendesk from '@dansmaculotte/vue-zendesk'


// Vue.use(Zendesk, {
//   key: '8d47ff27-10e1-4d4d-b994-fcf8158b97af',
//   disabled: true,
//   hideOnLoad: true,
//   settings: {
//     webWidget: {
//       color: {
//         theme: '#78a300'
//       }
//     }
//   }
// })

Vue.use(Auth0Plugin, {
  audience: 'https://uplaw-dev.us.auth0.com/api/v2/',
  domain:'auth.uplaw.fr',
  clientId:'bTgYWpEhneSyKbuiQLvx6WbLx2tO7uaI',
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
