import { getInstance } from '../auth';
import axios from 'axios';

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check the auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before checking isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const adminGuard = async (to, from, next) => {
  try{
    const authService = getInstance();  
    if (authService.isAuthenticated){
      const userId=authService.user.sub
      axios.get(`https://prod.uplaw-api.com/auth/user/${userId}`).then(res => {
      const roles = res.data.data.roles.map(role =>{
        return role.slug
      })
      if (roles.includes("administrator") || roles.includes("super-administrator")){
        console.log("zferf",roles)
        return next();
      } else{
          return next('/document-category')
      }
    })
    } 
    else{
      return next('/document-category')
     }

  }catch(e){
    console.log("error:",e)
  }

}