<template>
    <b-modal
        :id="id"
        :title="title"
        ref="myref"
        ok-title="Confirmer"
        cancel-variant="outline-secondary"
        @show="resetModalAdd"
        @hidden="resetModalAdd"
        @ok="handleOkAdd"
        centered
        :v-model="addOpen"
        >

        <form
            ref="form"
        >
            <b-form-group
            :state="nameState"
            label="Nom"
            label-for="name-input"
            invalid-feedback="Ce champ est requis"
            >
            <b-form-input
                required
                id="name-input"
                v-model="name"
                :state="nameState"
            />

            </b-form-group>

            <b-form-group
            :state="descriptionState"
            label="Description"
            label-for="description-input"
            >
            <b-form-input
                id="description-input"
                v-model="description"
                :state="descriptionState"
            />
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
import axios from 'axios';
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,VBModal,BModal,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    title: {
        type:String,
        required: false,
    },
  },
  data() {
      return {
        name: '',
        nameState: null,
        nameStateUpdate:null,
        description: '',
        descriptionState: null,
        addOpen: false,
        currentName:'',
        currentDescription:''
      }
  },
  methods: {
    add(props){
      this.addOpen= true;
    },
    resetModalAdd() {
      this.name = '';
      this.nameState = null;
      this.description = '';
      this.descriptionState = null;
      this.addOpen = false;

    },
    handleOkAdd(bvModalEvtAdd) {
      // Prevent modal from closing
      bvModalEvtAdd.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async checkFormValidity() {
      const valid = await this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    async handleSubmit() {

      if (await this.checkFormValidity()==false) {
        return
      }
      else {

        await  axios
      .post('https://uplaw-api.com/doc-categories/',{
        name:this.name,
        description:this.description
      })

      const response = await axios.get('https://uplaw-api.com/doc-categories?offset=0&limit=30');
      const rows = response.data;

      watch(rows, val => {
        store.commit('document/UPDATE_ROWS', val)
      })
      }

      this.$nextTick(() => {
        this.$refs['myref'].toggle('#toggle-btn')
      })
        
    },
  }
}
</script>