<template>
  <b-card-code title="">

    <div class="header-elements">
      
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- Add input -->
    <button-add
      name="docButtonAdd"
      icon="FolderPlusIcon"
      v-b-modal.modal-doc-add   
    />
    </div>

    <!-- <modal-add
      id="modal-doc-add"
      title="Ajouter une catégorie de document"
    /> -->

    <!-- table -->
    <b-modal
      id="modal-doc-add"
      ref="my-modal"
      title="Ajouter un rôle"
      ok-title="Confirmer"
      cancel-variant="outline-secondary"
      @show="resetModalAdd"
      @hidden="resetModalAdd"
      @ok="handleOkAdd"
      centered
      v-model="addOpen"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="nameState"
          label="Nom"
          label-for="name-input"
          invalid-feedback="Ce champ est requis"
        >
          <b-form-input
            required
            id="name-input"
            v-model="name"
            :state="nameState"
          />
        </b-form-group>
        <b-form-group
          :state="descriptionState"
          label="Description"
          label-for="description-input"
          invalid-feedback="Description is required"
        >
          <b-form-input
            required
            id="description-input"
            v-model="description"
            :state="descriptionState"
          />
        </b-form-group>
        <b-form-group
          :state="slugState"
          label="Slug"
          label-for="slug-input"
          invalid-feedback="Slug is required"
        >
          <b-form-input
            required
            id="slug-input"
            v-model="slug"
            :state="slugState"
          />
        </b-form-group>
        <b-form-group class="select"
          label="Priorité"
          label-for="bg-variant"
          label-cols-sm="4"
          label-cols-lg="12"
          centered
          :state="priorityState"
          invalid-feedback="Ce champ est requis"
        >
          <b-form-select
            required
            defaultValue="0"
            id="bg-variant"
            v-model="priority"
            :options="priorities"
            :state="priorityState"
          />

        </b-form-group>
        <b-form-checkbox
          v-model="isContactRequired"
        >
          Contact requis
        </b-form-checkbox>
      </form>
    </b-modal>

    <!-- table -->
    <vue-good-table class="tablecus"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'" >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
              v-b-modal.modal-prevent-closing-update
              @click="update(props.row)"
              
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>
                  Modifier
                </span>
              </b-dropdown-item>

              <b-modal
                id="modal-prevent-closing-update"
                ref="my-modal-update"
                title="Modifier le rôle"
                ok-title="Submit"
                cancel-variant="outline-secondary"
                @show="ModalUpdate(props)"
                @hidden="resetModalUpdate"
                @ok="handleOkUpdate"
                v-model="updateOpen"
                centered
                :name="name"
              >
                <form
                  ref="formUpdate"
                  @submit.stop.prevent="handleUpdate()"
                >
                  <b-form-group
                    :state="nameState"
                    label="Nom"
                    label-for="name-input"
                    invalid-feedback="Ce champ est requis"
                  >
                    <b-form-input
                      id="name-input"
                      :value="props.row.name"
                      v-model="name"
                      :state="nameState"
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    :state="descriptionState"
                    label="Description"
                    label-for="description-input"
                    
                  >
                    <b-form-input
                      required
                      id="description-input"
                      v-model="description"
                      :state="descriptionState"
                      
                    />
                  </b-form-group>
                  <b-form-group
                    :state="slugState"
                    label="Slug"
                    label-for="slug-input"
                    invalid-feedback="Slug is required"
                    >
                    <b-form-input
                        required
                        id="slug-input"
                        v-model="slug"
                        :state="slugState"
                    />
                  </b-form-group>
                  <b-form-group class="select"
                    label="Priorité"
                    label-for="bg-variant"
                    label-cols-sm="4"
                    label-cols-lg="12"
                    centered
                    :state="priorityState"
                    invalid-feedback="Ce champ est requis"
                  >
                    <b-form-select
                      required
                      defaultValue="0"
                      id="bg-variant"
                      v-model="priority"
                      :options="priorities"
                      :state="priorityState"
                    />

                  </b-form-group>
                  <b-form-checkbox
                    v-model="isContactRequired"
                  >
                    Contact requis
                  </b-form-checkbox>
                </form>
              </b-modal>

              <b-dropdown-item
              @click="showMsgBoxTwo(props.row)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>
                  Supprimer
                </span>
              </b-dropdown-item>

              <b-dropdown-item
              v-b-modal.modal-prevent-closing-link
              @click="link(props.row)"
              >
                <feather-icon
                  icon="LinkIcon"
                  class="mr-50"
                />
                <span>
                  Linker
                </span>
              </b-dropdown-item>

              <b-modal
                id="modal-prevent-closing-link"
                ref="my-modal-link"
                title="Relier des permissions à un rôle"
                ok-title="Submit"
                cancel-variant="outline-secondary"
                @show="ModalLink(props)"
                @hidden="resetModalLink"
                @ok="handleOkLink"
                v-model="linkOpen"
                centered
                size= "xl"
                :name="name"
              >
                <form
                  ref="formLink"
                  @submit.stop.prevent="handleLink(props.row.id)"
                >
                  <b-form-group
                    :state="nameState"
                    label="Nom"
                    label-for="name-input"
                  >
                    <b-form-input
                      id="name-input"
                      :value="props.row.name"
                      v-model="name"
                      :state="nameState"
                      disabled
                    />
                  </b-form-group>
                  <div class="big-container">
                  <h6 class="font-weight-bolder">
                    Public scope:
                  </h6>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="writeVestings"
                      value="write:vestings"
                    >
                      Créer des vestings
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="readVestings"
                      value="read:vestings"
                    >
                      Voir les vestings
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeDocuments"
                      value="write:documents"

                    >
                      Créer des documents
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readDocuments"
                      value="read:documents"
                    >
                      Voir les documents
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeEvents"
                      value="write:events"
                    >
                      Créer des évènements
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readDirectory"
                      value="read:directory"
                    >
                      Voir l'annuaire
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeContacts"
                      value="write:contacts"
                    >
                      Créer des contacts
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readAudits"
                      value="read:audits"
                    >
                      Voir les audits
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeAudits"
                      value="write:audits"
                    >
                      Créer les audits
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readContacts"
                      value="read:contacts"
                    >
                      Voir les contacts
                    </b-form-checkbox>
                  </div>
                  <h6 class="font-weight-bolder">
                    Secret scope:
                  </h6>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="writeUsers"
                      value="write:users"
                    >
                      Créer des utilisateurs
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readUsers"
                      value="read:users"
                    >
                      Voir des utilisateurs
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeCompanies"
                      value="write:companies"
                    >
                      Créer des entreprises
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readCompanies"
                      value="read:companies"
                    >
                      Accéder aux entreprises
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="selfContact"
                      value="self:contact"
                    >
                      Voir que sa propre fiche contact
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeAutomation"
                      value="write:automation"
                    >
                      Accéder à la Doc Automation
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeConfig"
                      value="write:config"
                    >
                      Accéder à la configuration (write:config)
                    </b-form-checkbox>
                  </div>
                  <h6 class="font-weight-bolder">
                    Configurator scope:
                  </h6>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="writeStocks"
                      value="write:stocks"
                    >
                      Créer des actions
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readStocks"
                      value="read:stocks"
                    >
                      Voir les actions 
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="employeeReadStocks"
                      value="employee:readStocks"
                    >
                      Voir que ses titres
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="employeeReadTransactions"
                      value="employee:readTransactions"
                    >
                      Voir que ses transactions
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="employeeReadDocuments"
                      value="employee:readDocuments"
                    >
                      Voir que ses documents
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="employeeWriteContact"
                      value="employee:writeContact"
                    >
                      Modifier sa fiche  Employé(e)
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeOperations"
                      value="write:operations"
                    >
                      Créer des opérations
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readOperations"
                      value="read:operations"
                    >
                      Voir les opérations
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeCommittees"
                      value="write:committees"
                    >
                      Créer des comités
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readCommittees"
                      value="read:committees"
                    >
                      Voir les comités
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeProfitSharingPlan"
                      value="write:profitSharingPlan"
                    >
                      Créer des plans d'intéressement
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeFundRaisingRound"
                      value="write:fundRaisingRound"
                    >
                      Créer des tours de financement
                    </b-form-checkbox>
                  </div>
                  <h6 class="font-weight-bolder">
                    Tables scope:
                  </h6>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="readCaptable"
                      value="read:captable"
                    >
                      Voir la captable
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readCorporate"
                      value="read:corporate"
                    >
                      Voir la base juridique
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readDashboard"
                      value="read:dashboard"
                    >
                      Voir le dashboard
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readEvents"
                      value="read:events"
                    >
                      Voir les évènements
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readOwnership"
                      value="read:ownership"
                    >
                      Voir l'actionnariat salarié
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readTransactions"
                      value="read:transactions"
                    >
                      Voir le RMT
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readDelegations"
                      value="read:delegations"
                    >
                      Voir les délégations
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="writeDelegations"
                      value="write:delegations"
                    >
                      Créer les délégations
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readDecisions"
                      value="read:decisions"
                    >
                      Voir le registre des décisions du président
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readMandates"
                      value="read:mandates"
                    >
                      Voir les mandats
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="readMeetings"
                      value="read:meetings"
                    >
                      Voir le registre des assemblées
                    </b-form-checkbox>
                  </div>
                  <h6 class="font-weight-bolder">
                    Export scope:
                  </h6>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="readExport"
                      value="read:export"
                    >
                      Exporter
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="downloadDocuments"
                      value="download:documents"
                    >
                      Télécharger les documents
                    </b-form-checkbox>
                  </div>
                  </div>
                </form>
              </b-modal>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
      <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script >
import axios from 'axios';
import { watch } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,VBModal,BModal,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from '../code'
import { props } from 'vue-prism-component'
import ButtonAdd from '@/components/buttons/ButtonAdd.vue'
import MySearchBox from '@/components/search/MySearchBox.vue'
import ModalAdd from '@/components/modals/ModalAdd.vue';
import vSelect from 'vue-select'
import { BFormCheckbox } from 'bootstrap-vue'
import { getInstance } from '../../auth';

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ButtonAdd,
    MySearchBox,
    ModalAdd,
    vSelect,
    BFormCheckbox
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      
      pageLength: 10,
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Slug',
          field: 'slug',
        },
        {
          label: 'Priority',
          field: 'priority',
        },

        {
          label: 'Action',
          field: 'action',
          custom: true,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      name: '',
      nameState: null,
      description: '',
      descriptionState: null,
      slug:'',
      slugState: null,
      priority: null,
      priorityState: null,
      addOpen: false,
      updateOpen:false,
      id: '',
      currentName:'',
      currentDescription:'',
      currentSlug:'',
      currentPriority: null,
      linkOpen: false,
      permissionsList: [],
      permissionsMapped: new Map(),
      permissions: [],
      permissionState: null,
      currentPermissions: [],
      priorities: [0,1,2,3,4,5,6,7,8,9],

      permissionSelected: [],
      
      writeVestings: '',
      currentWriteVestings:'',

      readVestings: '',
      currentReadVestings:'',

      writeDocuments: '',
      currentWriteDocuments:'',

      readDocuments: '',
      currentReadDocuments:'',

      writeEvents: '',
      currentWriteEvents:'',

      readDirectory: '',
      currentReadDirectory:'',

      writeContacts: '',
      currentWriteContacts:'',

      readAudits: '',
      currentReadAudits:'',

      readContacts: '',
      currentReadContacts:'',

      writeUsers: '',
      currentWriteUsers:'',
      writeCompanies:'',
      currentWriteCompanies:'',
      selfContact:'',
      currentSelfContact:'',
      writeAutomation:'',
      currentWriteAutomation:'',

      writeStocks:'',
      currentWriteStocks:'',
      readStocks:'',
      currentReadStocks:'',
      writeOperations:'',
      currentWriteOperations:'',
      readOperations:'',
      currentReadOperations:'',
      writeCommittees:'',
      currentWriteCommittes:'',
      readCommittees:'',
      currentReadCommittees:'',
      writeProfitSharingPlan:'',
      currentWriteProfitSharingPlan:'',
      writeFundRaisingRound:'',
      currentWriteFundRaisingRound:'',

      readCaptable:'',
      currentReadCaptable:'',
      readCorporate:'',
      currentReadCorporate:'',
      readDashboard:'',
      currentReadDashboard:'',
      readEvents:'',
      currentReadEvents:'',
      readOwnership:'',
      currentReadOwnership:'',
      readTransactions:'',
      currentReadTransactions:'',
      readDelegations:'',
      currentReadDelegations:'',
      readDecisions:'',
      currentReadDecisions:'',
      readMandates:'',
      currentReadMandates:'',
      readMeetings:'',
      currentReadMeetings:'',

      readExport:'',
      currentReadExport:'',

      readCompanies:'',
      currentReadCompanies:'',

      readUsers:'',
      currentReadUsers:'',

      writeAudits:'',
      currentWriteAudits:'',

      writeDelegations:'',
      currentWriteDelegations:'',

      downloadDocuments:'',
      currentDownloadDocuments:'',

      writeConfig:'',
      currentWriteConfig:'',

      employeeReadStocks:'',
      currentemployeeReadStocks:'',

      employeeReadTransactions:'',
      currentemployeeReadTransactions:'',

      employeeReadDocuments:'',
      currentemployeeReadDocuments:'',

      employeeWriteContact:'',
      currentemployeeWriteContact:'',

      isContactRequired:false,
      currentIsContactRequired:'',

    }
  },

  computed: {
    
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  
  created() {

      axios
      .get('https://prod.uplaw-api.com/roles?offset=0&limit=30')
      .then(res => {
        this.rows = res.data.data
      })

      axios
      .get('https://prod.uplaw-api.com/permissions?offset=0&limit=30')
      .then(res => {
        res.data.data.map(element =>{
            this.permissionsList.push(element.name)
        })
      })
      axios
      .get('https://prod.uplaw-api.com/permissions?offset=0&limit=30')
      .then(res => {
        res.data.data.map(element =>{
            this.permissionsMapped.set(element.slug,element.id)
        })
      })
  },

  methods: {
    add(props){
      this.addOpen= true;
    },
    update(props){
      this.id=props.id;
      this.updateOpen= true;
      this.currentName=props.name;
      this.currentDescription=props.description;
      this.currentSlug = props.slug
      this.currentPriority = props.priority
      this.currentIsContactRequired = props.isContactRequired
    },
    link(props){
      this.id=props.id;
      this.linkOpen=true;
      this.currentName=props.name;
      props.permissions.map( p => {
        if (p.slug== 'write:vestings')
        this.currentWriteVestings= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:vestings')
        this.currentReadVestings= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:documents')
        this.currentWriteDocuments= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:documents')
        this.currentReadDocuments= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:events')
        this.currentWriteEvents= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:directory')
        this.currentReadDirectory= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:contacts')
        this.currentWriteContacts= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:audits')
        this.currentReadAudits= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:contacts')
        this.currentReadContacts= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:users')
        this.currentWriteUsers= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:companies')
        this.currentWriteCompanies= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'self:contact')
        this.currentSelfContact= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:automation')
        this.currentWriteAutomation= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:stocks')
        this.currentWriteStocks= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:stocks')
        this.currentReadStocks= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:operations')
        this.currentWriteOperations= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:operations')
        this.currentReadOperations= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:committees')
        this.currentWriteCommittes= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:committees')
        this.currentReadCommittees= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:profitSharingPlan')
        this.currentWriteProfitSharingPlan= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:fundRaisingRound')
        this.currentWriteFundRaisingRound= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:captable')
        this.currentReadCaptable= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:corporate')
        this.currentReadCorporate= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:dashboard')
        this.currentReadDashboard= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:events')
        this.currentReadEvents= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:ownership')
        this.currentReadOwnership= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:transactions')
        this.currentReadTransactions= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:delegations')
        this.currentReadDelegations= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:decisions')
        this.currentReadDecisions= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:mandates')
        this.currentReadMandates= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:meetings')
        this.currentReadMeetings= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:export')
        this.currentReadExport= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:companies')
        this.currentReadCompanies= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'read:users')
        this.currentReadUsers= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:audits')
        this.currentWriteAudits= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:delegations')
        this.currentWriteDelegations= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'download:documents')
        this.currentDownloadDocuments= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'write:config')
        this.currentWriteConfig= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'employee:readStocks')
        this.currentemployeeReadStocks= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'employee:readTransactions')
        this.currentemployeeReadTransactions= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'employee:readDocuments')
        this.currentemployeeReadDocuments= p.slug
      });
      props.permissions.map( p => {
        if (p.slug== 'employee:writeContact')
        this.currentemployeeWriteContact= p.slug
      });
    },

    resetModalAdd() {
      this.name = '';
      this.nameState = null;
      this.description = '';
      this.descriptionState = null;
      this.slug = '';
      this.slugState = null;
      this.priority = null;
      this.priorityState = null;
      this.isContactRequired = null;
      this.addOpen = false;

    },
    ModalUpdate(props){
      this.name = this.currentName;
      this.description = this.currentDescription;
      this.slug = this.currentSlug;
      this.priority = this.currentPriority;
      this.isContactRequired = this.currentIsContactRequired;
    },

    ModalLink(props){
      this.name = this.currentName;
      this.writeVestings=this.currentWriteVestings
      this.readVestings=this.currentReadVestings
      this.writeDocuments=this.currentWriteDocuments
      this.readDocuments=this.currentReadDocuments
      this.writeEvents=this.currentWriteEvents
      this.readDirectory=this.currentReadDirectory
      this.writeContacts=this.currentWriteContacts
      this.readAudits=this.currentReadAudits
      this.readContacts=this.currentReadContacts
      this.writeCompanies=this.currentWriteCompanies
      this.writeUsers=this.currentWriteUsers
      this.selfContact=this.currentSelfContact
      this.writeAutomation=this.currentWriteAutomation
      this.writeStocks=this.currentWriteStocks
      this.readStocks=this.currentReadStocks
      this.writeOperations=this.currentWriteOperations
      this.readOperations=this.currentReadOperations
      this.writeCommittees=this.currentWriteCommittes
      this.readCommittees=this.currentReadCommittees
      this.writeProfitSharingPlan=this.currentWriteProfitSharingPlan
      this.writeFundRaisingRound=this.currentWriteFundRaisingRound
      this.readCaptable=this.currentReadCaptable
      this.readCorporate=this.currentReadCorporate
      this.readDashboard=this.currentReadDashboard
      this.readEvents=this.currentReadEvents
      this.readOwnership=this.currentReadOwnership
      this.readTransactions=this.currentReadTransactions
      this.readDelegations=this.currentReadDelegations
      this.readDecisions=this.currentReadDecisions
      this.readMandates=this.currentReadMandates
      this.readMeetings=this.currentReadMeetings
      this.readExport=this.currentReadExport
      this.readCompanies=this.currentReadCompanies
      this.readUsers=this.currentReadUsers
      this.writeAudits=this.currentWriteAudits
      this.writeDelegations=this.currentWriteDelegations
      this.downloadDocuments=this.currentDownloadDocuments
      this.writeConfig=this.currentWriteConfig
      this.employeeReadStocks=this.currentemployeeReadStocks
      this.employeeReadTransactions=this.currentemployeeReadTransactions
      this.employeeReadDocuments=this.currentemployeeReadDocuments
      this.employeeWriteContact=this.currentemployeeWriteContact
    },

    resetModalUpdate() {
      this.name = '';
      this.nameState = null;
      this.description = '';
      this.descriptionState = null;
      this.slug = '';
      this.slugState = null;
      this.priority = null;
      this.currentPriority = null;
      this.updateOpen = false;
      this.isContactRequired = null;
    },

    resetModalLink() {
      this.name = '';
      // this.permissions = [];
      this.linkOpen = false;
      this.currentWriteVestings= ''
      this.currentReadVestings = ''
      this.currentWriteDocuments=''
      this.currentReadDocuments=''
      this.currentWriteEvents=''
      this.currentReadDirectory=''
      this.currentWriteContacts=''
      this.currentReadAudits=''
      this.currentReadContacts=''
      this.currentWriteCompanies=''
      this.currentWriteUsers=''
      this.currentSelfContact=''
      this.currentWriteAutomation=''
      this.currentWriteStocksthis=''
      this.currentReadStocks=''
      this.currentWriteOperations=''
      this.currentReadOperations=''
      this.currentWriteCommittes=''
      this.currentReadCommittees=''
      this.currentWriteProfitSharingPlan=''
      this.currentWriteFundRaisingRound=''
      this.currentReadCaptable=''
      this.currentReadCorporate=''
      this.currentReadDashboard=''
      this.currentReadEvents=''
      this.currentReadOwnership=''
      this.currentReadTransactions=''
      this.currentReadDelegations=''
      this.currentReadDecisions=''
      this.currentReadMandates=''
      this.currentReadMeetings=''
      this.currentReadExport=''
      this.currentWriteStocks=''
      this.currentReadCompanies=''
      this.currentReadUsers=''
      this.currentWriteAudits=''
      this.currentWriteDelegations=''
      this.currentDownloadDocuments=''
      this.currentWriteConfig=''
      this.currentemployeeReadStocks=''
      this.currentemployeeReadTransactions=''
      this.currentemployeeReadDocuments=''
      this.currentemployeeWriteContact=''
   },
    
    handleOkAdd(bvModalEvtAdd) {
      // Prevent modal from closing
      bvModalEvtAdd.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleOkUpdate(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleUpdate()
    },
    handleOkLink(bvModalEvtLink){
      // Prevent modal from closing
      bvModalEvtLink.preventDefault()
      // Trigger submit handler
      this.handleLink()
    },

    async checkFormValidity() {
      const valid = await this.$refs.form.checkValidity();
      this.nameState = valid;
      this.descriptionState = valid;
      this.slugState = valid;
      this.priorityState = valid;
      return valid
    },

    async checkUpdateValidity(){
      const valid = await this.$refs.formUpdate.checkValidity()
      this.nameState = valid;
      this.descriptionState = valid;
      this.slugState = valid;
      this.priorityState = valid;
      return valid
    },

    async handleSubmit() {

      if (await this.checkFormValidity()==false) {
        return
      }
      else {
        await  axios
      .post('https://prod.uplaw-api.com/roles/',{
        name:this.name,
        description:this.description,
        slug: this.slug,
        priority: this.priority,
        isContactRequired: this.isContactRequired
      })
      .then(res => {
        console.log(res)
        axios
      .get('https://prod.uplaw-api.com/roles?offset=0&limit=30')
      .then(res => {
        this.rows = res.data.data
      })
      })
      }

      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
        
    },

    async handleUpdate() {

      if (await this.checkUpdateValidity()==false) {
        return
      }
      else{
          console.log("name:", this.name, "slug:", this.slug)
        await axios
      .put(`https://prod.uplaw-api.com/roles/${this.id}`,{
        name:this.name,
        description:this.description,
        slug: this.slug,
        priority: this.priority,
        isContactRequired: this.isContactRequired
      })
      .then(res => {
        this.name = '',
        this.description = '',
        this.slug = '',
        this.priority = null,
        this.isContactRequired = null,
        this.id = ''
        axios
      .get('https://prod.uplaw-api.com/roles?offset=0&limit=30')
      .then(res => {
        this.rows = res.data.data
      })
      })
      }
      

      this.$nextTick(() => {
        this.$refs['my-modal-update'].toggle('#toggle-btn')
      })
    },

    showMsgBoxTwo(props) {
      this.id=props.id
      console.log(this.id)

      this.$bvModal
        .msgBoxConfirm('Êtes-vous sûr(e) de vouloir supprimer ce rôle ?', {
          modalClass:"modal-danger",
          title: 'Supprimer',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value ==true) {
            axios
            .delete(`https://prod.uplaw-api.com/roles/${this.id}`)
            .then(res => {
            this.id = '';
            axios
          .get('https://prod.uplaw-api.com/roles?offset=0&limit=30')
          .then(res => {
            this.rows = res.data.data
          })
          })
          
          }
        })
    },

    getId(value) {
      console.log("hereee")
      //console.log("la value", value)
      //  return [...this.permissionsMapped].find(([key, val]) => val == value)[0]
     // const data = Array.from(this.permissionsMapped)
      //const [id, slug] = data.find(x=> x.includes(value));
      //return id;
      console.log(value,this.permissionsMapped.get(value))
      return this.permissionsMapped.get(value)
    },

    async handleLink(){
      console.log("bbbbbb",this.permissionSelected)
      this.writeVestings ? this.permissionSelected.push(this.writeVestings) : console.log("")
      this.readVestings ? this.permissionSelected.push(this.readVestings) : console.log("")
      this.writeDocuments ? this.permissionSelected.push(this.writeDocuments): console.log("")
      this.readDocuments ? this.permissionSelected.push(this.readDocuments): console.log("")
      this.writeEvents ? this.permissionSelected.push(this.writeEvents): console.log("")
      this.readDirectory ? this.permissionSelected.push(this.readDirectory): console.log("")
      this.writeContacts ? this.permissionSelected.push(this.writeContacts): console.log("")
      this.readContacts ? this.permissionSelected.push(this.readContacts): console.log("")
      this.readAudits ? this.permissionSelected.push(this.readAudits) : console.log("")
      this.writeUsers ? this.permissionSelected.push(this.writeUsers) : console.log("")
      this.writeCompanies ? this.permissionSelected.push(this.writeCompanies) : console.log("")
      this.selfContact ? this.permissionSelected.push(this.selfContact) : console.log("")
      this.writeAutomation ? this.permissionSelected.push(this.writeAutomation) : console.log("")
      this.writeStocks ? this.permissionSelected.push(this.writeStocks) : console.log("")
      this.readStocks ? this.permissionSelected.push(this.readStocks) : console.log("")
      this.writeOperations ? this.permissionSelected.push(this.writeOperations) : console.log("")
      this.readOperations ? this.permissionSelected.push(this.readOperations) : console.log("")
      this.writeCommittees ? this.permissionSelected.push(this.writeCommittees) : console.log("")
      this.readCommittees ? this.permissionSelected.push(this.readCommittees) : console.log("")
      this.writeProfitSharingPlan ? this.permissionSelected.push(this.writeProfitSharingPlan) : console.log("")
      this.writeFundRaisingRound ? this.permissionSelected.push(this.writeFundRaisingRound) : console.log("")
      this.readCaptable ? this.permissionSelected.push(this.readCaptable) : console.log("")
      this.readCorporate ? this.permissionSelected.push(this.readCorporate) : console.log("")
      this.readDashboard ? this.permissionSelected.push(this.readDashboard) : console.log("")
      this.readEvents ? this.permissionSelected.push(this.readEvents) : console.log("")
      this.readOwnership ? this.permissionSelected.push(this.readOwnership) : console.log("")
      this.readTransactions ? this.permissionSelected.push(this.readTransactions) : console.log("")
      this.readDelegations ? this.permissionSelected.push(this.readDelegations) : console.log("")
      this.readDecisions ? this.permissionSelected.push(this.readDecisions) : console.log("")
      this.readMandates ? this.permissionSelected.push(this.readMandates) : console.log("")
      this.readMeetings ? this.permissionSelected.push(this.readMeetings) : console.log("")
      this.readExport ? this.permissionSelected.push(this.readExport) : console.log("")
      this.readCompanies ? this.permissionSelected.push(this.readCompanies) : console.log("")
      this.readUsers ? this.permissionSelected.push(this.readUsers) : console.log("")
      this.writeAudits ? this.permissionSelected.push(this.writeAudits) : console.log("")
      this.writeDelegations ? this.permissionSelected.push(this.writeDelegations) : console.log("")
      this.downloadDocuments ? this.permissionSelected.push(this.downloadDocuments) : console.log("")
      this.writeConfig ? this.permissionSelected.push(this.writeConfig) : console.log("")
      this.employeeReadStocks ? this.permissionSelected.push(this.employeeReadStocks) : console.log("")
      this.employeeReadTransactions ? this.permissionSelected.push(this.employeeReadTransactions) : console.log("")
      this.employeeReadDocuments ? this.permissionSelected.push(this.employeeReadDocuments) : console.log("")
      this.employeeWriteContact ? this.permissionSelected.push(this.employeeWriteContact) : console.log("")
      const permissionIds = []
      for (let p of this.permissionSelected){
        if(this.getId(p)){
          permissionIds.push(this.getId(p))
        }
      }

      const token = await getInstance().getTokenSilently()

      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }

      await axios
      .put(`https://prod.uplaw-api.com/roles/${this.id}/permissions`,{
        ids:permissionIds,
      },
      config
      )
      .then(res => {
        console.log("response",res)
        this.name = '',
        this.permissions = [],
        this.permissionSelected = [],
        this.id = ''
        axios
      .get('https://prod.uplaw-api.com/roles?offset=0&limit=30')
      .then(res => {
        this.rows = res.data.data
      })
      })
      this.permissionSelected = []

      this.$nextTick(() => {
        this.$refs['my-modal-link'].toggle('#toggle-btn')
      })

    }

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

  .header-elements{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top:-20px;

    .button{
      margin-bottom: 28px;
      height: 45px;
      width: 150px;
      font-size: 1.1rem;

      .icon{
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .demo-inline-spacing{
    margin-top: -1rem;
    margin-bottom: 1rem;
    
  }
  // .big-container{
  //   display:flex;
  //   flex-wrap:wrap;
  //   justify-content: flex-start;
  // }
  // .tablecus{
  //   margin-top: 20px;
  // }
</style>
