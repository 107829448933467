import Vue from 'vue'
import VueRouter from 'vue-router'
import { adminGuard,authGuard } from './authGuard';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', 
      redirect: { 
        name: 'login' 
      } 
    },
    {
      path: '/document-csv',
      name: 'document-csv',
      component: () => import('@/views/app/DocumentCSV.vue'),
      meta: {
        pageTitle: 'Import CSV de document',

      },
      beforeEnter: authGuard,
    },
    {
      path: '/document-category',
      name: 'document-category',
      component: () => import('@/views/app/DocumentCategory.vue'),
      beforeEnter: authGuard,
      meta: {
        pageTitle: 'Catégorie de document',
        // breadcrumb: [
        //   {
        //     text: 'Document Category',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/sub-document-category',
      name: 'sub-document-category',
      component: () => import('@/views/app/SubDocumentCategory.vue'),
      meta: {
        pageTitle: 'Sous-catégorie de document',

      },
      beforeEnter: authGuard,
    },
    {
      path: '/option-category',
      name: 'option-category',
      component: () => import('@/views/app/OptionCategory.vue'),
      meta: {
        pageTitle: "Catégories d'options",

      },
      beforeEnter: authGuard,
    },
    {
      path: '/action-category',
      name: 'action-category',
      component: () => import('@/views/app/StockCategory.vue'),
      meta: {
        pageTitle: "Catégories d'actions",

      },
      beforeEnter: authGuard,
    },
    {
      path: '/contact-status',
      name: 'contact-status',
      component: () => import('@/views/app/ContactStatus.vue'),
      meta: {
        pageTitle: "Statut de contacts",
      },
      beforeEnter: authGuard,
    },
    {
      path: '/legal-status',
      name: 'legal-status',
      component: () => import('@/views/app/LegalStatus.vue'),
      meta: {
        pageTitle: "Statut légal"
      },
      beforeEnter: authGuard,
    },
    {
      path: '/function',
      name: 'function',
      component: () => import('@/views/app/Function.vue'),
      meta: {
        pageTitle: "Fonctions"
      },
      beforeEnter: authGuard,
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/app/Roles.vue'),
      meta: {
        pageTitle: "Rôles"
      },
      beforeEnter: adminGuard
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: () => import('@/views/app/Permissions.vue'),
      meta: {
        pageTitle: "Permissions"
      },
      beforeEnter: adminGuard,
    },
    {
      path: '/motives',
      name: 'motives',
      component: () => import('@/views/app/Motives.vue'),
      meta: {
        pageTitle: "Motifs d'audit"
      },
      beforeEnter: authGuard,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
