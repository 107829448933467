export default [
  {
    header: 'Gestion',
  },
  {
    title: 'Documents',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Import CSV de document',
        route: 'document-csv',
        disabled:true,
      },
      {
        title: 'Catégorie de document',
        route: 'document-category',
      },
      {
        title: 'Sous-catégorie de document',
        route: 'sub-document-category',
      },
    ],
  },
  {
    title: 'Titres',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: "Option",
        route: 'option-category',
      },
      {
        title: "Action simple",
        route: 'action-category',
      },
    ],
  },
  {
    title: 'Statuts',
    icon: 'UsersIcon' ,
    children: [
      {
        title: "Statut de contacts",
        route: 'contact-status',
      },
      {
        title: 'Statut légal',
        route: 'legal-status',
      },
      {
        title: 'Fonction',
        route: 'function'
      }
    ]
  },
  {
    title: 'Admin',
    icon: 'AwardIcon' ,
    children: [
      {
        title: "Rôles",
        route: 'roles',
      },
      {
        title: 'Permissions',
        route: 'permissions',
      },
    ]
  },
  {
    title: 'Audit',
    icon: 'EditIcon' ,
    children: [
      {
        title: "Motifs d'audit",
        route: 'motives',
      },
    ]
  },
  
]
