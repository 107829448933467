export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Catégorie de document',
        route: 'document-category',
        icon: 'FolderIcon',
      },
      {
        title: 'Sous-catégorie de document',
        route: 'sub-document-category',
        icon: 'FileTextIcon',
      },
    ],
  },
]
